import React, { useEffect, useRef } from 'react';

import { graphql } from 'gatsby';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Seo from '../../components/Seo/Seo';
import Layout from '../../Layout';
import TitlePage from '../../Molecules/TitlePage/TitlePage';
import { getBreadCrumb } from '../../Utils/Utils';

const PageCookie = ({ data, pageContext }) => {
  const cookieRef = useRef(null);
  const { locale } = pageContext;
  const pageData = data?.footerData?.edges[0]?.node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData?.path?.alias);
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  
  useEffect(() => {
    let script = document.createElement('script');
    script.src = `https://consent.cookiebot.com/${process.env.GATSBY_COOKIEBOT_KEY}/cd.js`;
    script.setAttribute('data-culture', locale.toUpperCase());
    script.id = 'CookieDeclaration';
    script.async = true;
    //cookieRef.current?.innerHTML = ' ';
    cookieRef.current?.appendChild(script);
  }, [locale]);

  return (
    <Layout>
        <Seo
          title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
          description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}

        />
      <div className="page_template">
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: breadCrumb?.current?.name,
                url: breadCrumb?.current?.link,
              },
              locale: locale,
            }}
          />
          <TitlePage
            color="color_dark_bleu"
            title={pageData?.title}
            description=""
          />
        </div>
        {pageData?.body?.processed && <section className="section_content">
          <div
            className="wrapper_page_xs"
            dangerouslySetInnerHTML={{ __html: pageData?.body?.processed }}
          ></div>
        </section>}
        {/* {% if cookie and parameters is defined and parameters["cookiebot_key"] is defined %} */}
        <section className="section_content" id="cookie">
          <div className="wrapper_page_xs">
            {process.env.GATSBY_COOKIEBOT_KEY && (
              <div className="cookietable" ref={cookieRef}></div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};
//                <script id="CookieDeclaration" data-culture={dataCulture} src={`https://consent.cookiebot.com/${process.env.COOKIEBOT_KEY}/cd.js`} type="text/javascript" async></script>

export const query = graphql`
  query pageCookieTemplateQuery(
    $locale: String!
    $slug: String!
    $imageID: [Int!]
  ) {
    footerData: allNodeCookie(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          path {
            alias
          }
          field_metatag{
            title
            description
          }
          metatag {
            attributes {
              content
              name
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: { langcode: { eq: "fr" }, drupal_internal__mid: { in: $imageID } }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
              }
            }
          }
          langcode
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default PageCookie;
